/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "../../core/Error.js";
import { setDeepValue as t } from "../../core/object.js";
import { parse as r } from "./parser.js";
import { isEffectFunctions as s } from "./utils.js";
function n(e, t, r) {
  try {
    return c(e);
  } catch (s) {
    r?.messages?.push(s);
  }
  return null;
}
function a(e, r, s, n) {
  try {
    const n = o(e);
    t(s, n, r);
  } catch (a) {
    n.messages && n.messages.push(a);
  }
}
function o(e) {
  const t = r(e);
  return t ? s(t) ? t.map(e => e.toJSON()) : t.map(({
    scale: e,
    effects: t
  }) => ({
    scale: e,
    value: t.map(e => e.toJSON())
  })) : null;
}
function c(e) {
  if (!e || 0 === e.length) return null;
  if (f(e)) {
    const t = [];
    for (const r of e) t.push({
      scale: r.scale,
      value: i(r.value)
    });
    return t;
  }
  return i(e);
}
function f(e) {
  const t = e[0];
  return !!t && "scale" in t;
}
function i(e) {
  if (!e?.length) return "";
  const t = [];
  for (const s of e) {
    let e = [];
    switch (s.type) {
      case "grayscale":
      case "sepia":
      case "saturate":
      case "invert":
      case "brightness":
      case "contrast":
      case "opacity":
        e = [u(s, "amount")];
        break;
      case "blur":
        e = [u(s, "radius", "pt")];
        break;
      case "hue-rotate":
        e = [u(s, "angle", "deg")];
        break;
      case "drop-shadow":
        e = [u(s, "xoffset", "pt"), u(s, "yoffset", "pt"), u(s, "blurRadius", "pt"), l(s, "color")];
        break;
      case "bloom":
        e = [u(s, "strength"), u(s, "radius", "pt"), u(s, "threshold")];
    }
    const n = `${s.type}(${e.filter(Boolean).join(" ")})`;
    r(n), t.push(n);
  }
  return t.join(" ");
}
function u(t, r, s) {
  if (null == t[r]) throw new e("effect:missing-parameter", `Missing parameter '${r}' in ${t.type} effect`, {
    effect: t
  });
  return s ? t[r] + s : "" + t[r];
}
function l(t, r) {
  if (null == t[r]) throw new e("effect:missing-parameter", `Missing parameter '${r}' in ${t.type} effect`, {
    effect: t
  });
  const s = t[r];
  return `rgba(${s[0] || 0}, ${s[1] || 0}, ${s[2] || 0}, ${s[3] / 255 || 0})`;
}
export { i as effectFunctionsFromJSON, c as fromJSON, n as read, o as toJSON, a as write };